import { getOriginalNode } from "typescript";
import Axios from "../../../redux/setupAxios";
import AxiosWithoutInterceptor from "../../../redux/setupAxios"
import * as axios from 'axios';
import Suppliers from "../../components/supplier/Suppliers";




const API_URL = process.env.REACT_APP_API_URL
    // all api's
export const GET_USER_LIST = "user/list?page=";
export const GET_EVENT_LIST = "event/?page=";
export const UUSER_CRUD = "private-signup";
export const EVENT_CRUD = "event/";
export const MBR_TYPE_LIST = "mbrshptyp/list/";
export const GET_MBR_PLAN_FULL_LIST = "mbrshp-pln-overview/";
export const GET_EVENT_FULL_LIST = "/event/fetch_detail/";
export const MBR_PLAN_CAT_CRUD = "membership-plan-type-category/";
export const MBR_PLAN_TYPE_CRUD = "membership-plan-type/";
export const GET_CAT_BULLETINS = "membership-bulletins/list/";
export const BULLETIN_CRUD = "mbrshp-bulletins/";
export const BULLETIN_ITEM_CRUD = "mbrshp-bulletin-items/";
export const MBR_PLAN_MEDIA_CRUD = "membership-plan-media/";
export const MBR_EVENT_MEDIA_CRUD = "event_media/?";
export const MBR_EVENT_MEDIA_DEL = "event_media/";
export const MBR_EVENT_MEDIA_UPLOAD = "event_media/media_upload/";
export const MBR_EVENT_NODE_CREATE = "event_places/";
export const MBR_PLAN_FEE_CRUD = "membership-plan-fee/";
export const MBR_PLAN_FEE_TRAIL_CRUD = "membership-plan-fee-trail/";
export const MBR_PLAN_FEE_AMT_CRUD = "membership-plan-fee-amount/";
export const MBR_PLAN_term_condition = "membership-plan-type-category/";
export const PLAN_term_condition = "membership-plan-type-terms-and-conditions/";
export const level_variant = "level-variant";
export const category_event = "/event_categories/";
export const Barrack = "/barrack/";
export const Department = "/department/";
export const Node = "node/";
export const Initial_Get_Node = "node/get_nodes/";
export const Initial_Get_Feature = "/features/get_feature_by_parentfeature_id/";
export const Feature_Update = "/services/";
export const Actions_Update = "/actions/";


export const vertical = "vertical/";
export const get_ancestorsapi = "node/get_ancestors/";
export const tree_Barrack = "/tree-barrack";
export const category = "category/";

// get users list with pagination
export function getUserList(page) {
    return Axios.get(GET_USER_LIST + page);
}

export function getEventList(page) {
    return Axios.get(GET_EVENT_LIST + page);
}

// get single user
export function getSingleUser(id) {
    return Axios.get(UUSER_CRUD + id + "/");
}

// add new user
export function postUser(values) {
    return Axios.post(UUSER_CRUD, values);
}

// update a user
export function patchUser(id, values) {
    return Axios.patch(UUSER_CRUD + id + "/", values);
}

// delete user
export function deleteUser(id) {
    return Axios.delete(UUSER_CRUD + id + "/");
}

// get single user
export function getSingleEvent(id) {
    return Axios.get(EVENT_CRUD + id + "/");
}

// add new user
export function postEvent(values) {
    return Axios.post(EVENT_CRUD, values);
}

// update a user
export function patchEvent(id, values) {
    return Axios.patch(EVENT_CRUD + id + "/", values);
}

// delete user
export function deleteEvent(id) {
    return Axios.delete(EVENT_CRUD + id + "/");
}

// get membership type create
export function getMbrTypeList(id) {
    return Axios.get(MBR_TYPE_LIST);
}

// get membership plan full list
export function getMbrPlanFullList(id) {
    return Axios.get(GET_MBR_PLAN_FULL_LIST + id);
}
// get membership plan full list
export function getEventFullList(id) {
    return Axios.get(GET_EVENT_FULL_LIST + id);
}

// get membership plan type
export function getMbrPlanTypes(id) {
    return Axios.get(MBR_PLAN_TYPE_CRUD + id);
}

// add membership plan type
export function postMbrPlanType(id, values) {
    return Axios.post(MBR_PLAN_TYPE_CRUD + id, values);
}

// delete membership plan type
export function deleteMbrPlanType(id) {
    return Axios.delete(MBR_PLAN_TYPE_CRUD + id);
}

// add membership plan category
export function postMbrPlanCategory(id, values) {
    return Axios.post(MBR_PLAN_CAT_CRUD + id, values);
}

// get membership plan category
export function getMbrPlanCategory(id) {
    return Axios.get(MBR_PLAN_CAT_CRUD + id);
}

// get membership plan bulletins based on category
export function getCatBulletins(id) {
    return Axios.get(GET_CAT_BULLETINS + id);
}

// add membership plan bulletin
export function addBulletin(values) {
    return Axios.post(BULLETIN_CRUD, values);
}

// update membership plan bulletin
export function updateBulletin(id, title) {
    return Axios.patch(BULLETIN_CRUD + id + "/", { title: title });
}

// delete membership plan bulletin
export function deleteBulletin(id) {
    return Axios.delete(BULLETIN_CRUD + id + "/");
}

// add membership plan bulletin item
export function addBulletinItem(title, pln_id, bulletin_id, user_id) {
    return Axios.post(BULLETIN_ITEM_CRUD, {
        title: title,
        pln_id: pln_id,
        bulletin_id: bulletin_id,
        crtd_by: user_id,
    });
}

// get membership plan bulletin items
export function getBulletinItems() {
    return Axios.get(BULLETIN_ITEM_CRUD);
}

// update membership plan bulletin item
export function updateBulletinItem(id, title) {
    return Axios.patch(BULLETIN_ITEM_CRUD + id + "/", { title: title });
}

// delete membership plan bulletin item
export function deleteBulletinItem(id) {
    return Axios.delete(BULLETIN_ITEM_CRUD + id + "/");
}

// get membership plan media
export function getMbrPlanMedia(id, cat_id) {
    return Axios.get(MBR_PLAN_MEDIA_CRUD + id + "/" + cat_id);
}

// post membership plan media
export function uploadMbrPlanMedia(id, cat_id, values) {
    return Axios.post(MBR_PLAN_MEDIA_CRUD + id + "/" + cat_id, values);
}

// delete membership plan media
export function deleteMbrPlanMedia(id) {
    return Axios.delete(MBR_PLAN_MEDIA_CRUD + id);
}

// get membership plan media
export function getEventMedia(id, cat_id) {
    return Axios.get(MBR_EVENT_MEDIA_CRUD + id + "/" + cat_id);
}

// post  EVENT media
export function uploadEventMedia(values) {
    return Axios.post(MBR_EVENT_MEDIA_UPLOAD, values);
}

// delete membership plan media
export function deleteEventMedia(id) {
    return Axios.delete(MBR_EVENT_MEDIA_DEL + id + "/");
}

export function postPlcData(id, eventId) {
    return Axios.post(MBR_EVENT_NODE_CREATE, { event: eventId, place_id: id });
}

// get membership plan fee with datatable
export function getMbrPlanFee(id, format) {
    return Axios.get(MBR_PLAN_FEE_CRUD + id + "?format=" + format);
}

// add membership plan fee
export function postMbrPlanFee(id, values) {
    return Axios.post(MBR_PLAN_FEE_CRUD + id, values);
}

// get membership plan fee trail
export function getMbrPlanFeeTrail(id) {
    return Axios.get(MBR_PLAN_FEE_TRAIL_CRUD + id);
}

// add membership plan fee trail
export function postMbrPlanFeeTrail(id, values) {
    return Axios.post(MBR_PLAN_FEE_TRAIL_CRUD + id, values);
}

// get membership plan fee amount
export function getMbrPlanFeeAmt(id) {
    return Axios.get(MBR_PLAN_FEE_AMT_CRUD + id);
}

// add membership plan fee amount
export function postMbrPlanFeeAmt(id, values) {
    return Axios.post(MBR_PLAN_FEE_AMT_CRUD + id, values);
}

// get terms and condition list
export function getplanTermAndCondition(id, values) {
    return Axios.get(MBR_PLAN_term_condition + id, values);
}

// get level variant
export function GetLevelVarient(query, value, columnsDef) {
    return Axios.get(
        level_variant +
        "?custom_format=custom_datatables&" +
        query +
        value +
        "columnsDef[]=" + [columnsDef]
    );
}

// get Barrack
export function GetBarrack(query, value, columnsDef) {
    return Axios.get(
        Barrack +
        "?custom_format=custom_datatables&" +
        query +
        value +
        "columnsDef[]=" + [columnsDef]
    );
}

//get Department
export function GetDepartment(query, value, columnsDef) {
    return Axios.get(
        Department +
        "?custom_format=custom_datatables&" +
        query +
        value +
        "columnsDef[]=" + [columnsDef]
    );
}

// post level variant
export function PostLevelVarient(value) {
    return Axios.post(level_variant, value);
}
// get vertical id
export function Getvertical(value) {
    return Axios.get(vertical, value);
}

// post node variant
export function PostNode(value) {
    return Axios.post(Node, value);
}

// update node
export function PatchNode(id, value) {
    return Axios.patch(Node + id + "/", value);
}
export function PatchFeature(id, value) {
    return Axios.patch(Feature_Update + id + "/", value);
}
export function PatchAction(id, value) {
    return Axios.patch(Actions_Update + id + "/", value);
}

// get Category
export function Getcategoryname(value) {
    return Axios.get(category, value);
}

export function PostCategory(value) {
    return Axios.post(category_event, value);

}

// get event template category
export function getevectgry(id) {
    return Axios.get(category_event + id);
}

export function InitialGetNode(value) {
    return Axios.get(Initial_Get_Node + "?node_id=" + value);
}
export function InitialGetFeature(value) {
    return Axios.get(Initial_Get_Feature + "?parent_feature_id=" + value);
}

// Get Tree Barrack List

export function GetTreeBarrack(query, value, columnsDef, nodeId) {


    return Axios.get(
        tree_Barrack +
        "?node_id=" +
        nodeId +
        "&custom_format=custom_datatables&" +
        query +
        value +
        "columnsDef[]=" + [columnsDef]
    );
}

// Breadcrumbs
export function get_ancestors(value) {
    return Axios.get(get_ancestorsapi + "?node_id=" + value);
}
export const HTTP_CODE = {
    ACCEPTED: 202,
    BADREQUEST: 400,
    CONFLICT: 409,
    CREATED: 201,
    OK: 200,
};

// weeks 
export const STORE_WEEKDAYS_LIST = "/Store/Settings/ListWeekdays/";

export function getWeeksList() {
    return Axios.get(STORE_WEEKDAYS_LIST);
}
// section 
export const STORE_SECTIONS_LIST = "/Store/Table/Section/List/";
export const STORE_SECTIONS_GET = "/Store/Table/Section/Get/";
export const STORE_SECTIONS_ADD = "/Store/Table/Section/Add/";
export const STORE_SECTIONS_UPDATE = "/Store/Table/Section/Update/";
export const STORE_SECTIONS_REMOVE = "/Store/Table/Section/Remove/";

export function getSectionList() {
    return Axios.get(STORE_SECTIONS_LIST);
}

export function getSection(id) {
    return Axios.get(STORE_SECTIONS_GET + id + "/");
}

export function postSection(data) {
    return Axios.post(STORE_SECTIONS_ADD, data);
}
export function patchSection(id, values) {
    return Axios.patch(STORE_SECTIONS_UPDATE + id + "/", values);
}
export function deleteSections(id) {
    return Axios.delete(STORE_SECTIONS_REMOVE + id + "/");
}

// layouts 
export const STORE_LAYOUTS_LIST = "/Store/Table/Layout/List/";
export const STORE_LAYOUTS_GET = "/Store/Table/Layout/Get/";
export const STORE_LAYOUTS_ADD = "/Store/Table/Layout/Add/";
export const STORE_LAYOUTS_UPDATE = "/Store/Table/Layout/Update/";
export const STORE_LAYOUTS_REMOVE = "/Store/Table/Layout/Remove/";

export function getLayoutList() {
    return Axios.get(STORE_LAYOUTS_LIST);
}

export function getLayout(id) {
    return Axios.get(STORE_LAYOUTS_GET + id + "/");
}

export function postLayout(data) {
    return Axios.post(STORE_LAYOUTS_ADD, data);
}
export function patchLayout(id, values) {
    return Axios.patch(STORE_LAYOUTS_UPDATE + id + "/", values);
}
export function deleteLayouts(id) {
    return Axios.delete(STORE_LAYOUTS_REMOVE + id + "/");
}

//table
export const STORE_TABLES_LIST = "/Store/Table/List/";
export const STORE_TABLES_GET = "/Store/Table/Get/";
export const STORE_TABLES_ADD = "/Store/Table/Add/";
export const STORE_TABLES_UPDATE = "/Store/Table/Update/";
export const STORE_TABLES_REMOVE = "/Store/Table/Remove/";

export function getTablesList() {
    return Axios.get(STORE_TABLES_LIST);
}

export function getTables(id) {
    return Axios.get(STORE_TABLES_GET + id + "/");
}

export function postTables(data) {
    return Axios.post(STORE_TABLES_ADD, data);
}
export function patchTables(id, data) {
    return Axios.patch(STORE_TABLES_UPDATE + id + "/", data);
}
export function deleteTables(id) {
    return Axios.delete(STORE_TABLES_REMOVE + id + "/");
}

// timislot 

export const STORE_TIMESLOT_LIST = "/Store/Booking/Timeslot/List/";
export const STORE_TIMISLOT_GET = "/Store/Booking/Timeslot/Get/";
export const STORE_TIMESLOT_ADD = "/Store/Booking/Timeslot/Add/";
export const STORE_TIMESLOT_UPDATE = "/Store/Booking/Timeslot/Update/";
export const STORE_TIMESLOT_REMOVE = "/Store/Booking/Timeslot/Remove/";

export const ORG_SERVICES_SERVICEINSTANCEBYIDENTIFIER_GET = "/Org/Services/ServiceInstancesByIdentifier/Get/"

export function getOrgId(id, hostname) {
    return axios.get(ORG_SERVICES_SERVICEINSTANCEBYIDENTIFIER_GET + id + "/?domain=" + hostname)
}

export function getTimeslotList() {
    return Axios.get(STORE_TIMESLOT_LIST);
}

export function getTimeslot(id) {
    return Axios.get(STORE_TIMISLOT_GET + id + "/");
}

export function postTimeslot(data) {
    return Axios.post(STORE_TIMESLOT_ADD, data);
}
export function patchTimeslot(id, data) {
    return Axios.patch(STORE_TIMESLOT_UPDATE + id + "/", data);
}
export function deleteTimeslot(id) {
    return Axios.delete(STORE_TIMESLOT_REMOVE + id + "/");
}

// table timing 
export const STORE_TABLE_TIMING_LIST = "/Store/Booking/Timing/List/";
export const STORE_TABLE_TIMING_GET = "​/Store/Booking/Timing/Get/";
export const STORE_TABLE_TIMING_ADD = "/Store/Booking/Timing/Add/";
export const STORE_TABLE_TIMING_UPDATE = "/Store/Booking/Timing/Update/{id}/";
export const STORE_TABLE_TIMING_REMOVE = "/Store/Booking/Timing/Remove/";

export function getTableTimingList() {
    return Axios.get(STORE_TABLE_TIMING_LIST);
}

export function getTableTiming(id) {
    return Axios.get(STORE_TABLE_TIMING_GET + id + "/");
}

export function postTableTiming(data) {
    return Axios.post(STORE_TABLE_TIMING_ADD, data);
}
export function patchTableTiming(id, data) {
    return Axios.patch(STORE_TABLE_TIMING_UPDATE + id + "/", data);
}
export function deleteTableTiming(id) {
    return Axios.delete(STORE_TABLE_TIMING_REMOVE + id + "/");
}
// slot time list
export const STORE_TABLE_SLOT_LIST = "/Store/Table/Slot/List/";

export function getTableSlotList() {
    return Axios.get(STORE_TABLE_SLOT_LIST);
}

// list tables 
const STORE_BOOKING_TIMESLOT_List_TIMESLOT_ALLOTTED_TABLES = "/Store/Booking/Timeslot/ListTimeslotAllottedTables/"

export function getAllottedListTables() {
    return Axios.get(STORE_BOOKING_TIMESLOT_List_TIMESLOT_ALLOTTED_TABLES);
}
// booking Table 
export const STORE_BOOKING_TABLES_LIST = "/Store/Booking/List/";
export const STORE_BOOKING_TABLES_GET = "/Store/Booking/Get/";
export const STORE_BOOKING_TABLES_ADD = "/Store/Booking/Add/";
export const STORE_BOOKING_TABLES_UPDATE = "/Store/Booking/Update/";
export const STORE_BOOKING_TABLES_REMOVE = "/Store/Booking/Remove/";

export function getTableBookingList() {
    return Axios.get(STORE_BOOKING_TABLES_LIST);
}

export function getTableBooking(id) {
    return Axios.get(STORE_BOOKING_TABLES_GET + id + "/");
}

export function postTableBooking(data) {
    return Axios.post(STORE_BOOKING_TABLES_ADD, data);
}
export function patchTableBooking(id, data) {
    return Axios.patch(STORE_BOOKING_TABLES_UPDATE + id + "/", data);
}
export function deleteTableBooking(id) {
    return Axios.delete(STORE_BOOKING_TABLES_REMOVE + id + "/");
}

//tagset_store
export const TAGSET_STORE_LIST = "/Store/Tagset/List/";
export const TAGSET_STORE_GET = "/Store/Tagset/Get/";
export const TAGSET_STORE_ADD = "/Store/Tagset/Add/";
export const TAGSET_STORE_UPDATE = "/Store/Tagset/Update/";
export const TAGSET_STORE_REMOVE = "/Store/Tagset/Remove/";

export function getTagsetStoreList(Query) {
    return Axios.get(TAGSET_STORE_LIST + Query);
}
export function getTagsetStore(id) {
    return Axios.get(TAGSET_STORE_GET + id + "/");
}

export function postTagsetStore(data) {
    return Axios.post(TAGSET_STORE_ADD, data);
}
export function patchTagsetStore(id, data) {
    return Axios.patch(TAGSET_STORE_UPDATE + id + "/", data);
}
export function deleteTagsetStore(id) {
    return Axios.delete(TAGSET_STORE_REMOVE + id + "/");
}

//TaggedItem
export const TAGGEDITEM_LIST = "/Store/TagSet/TaggedItem/List/?";
export const TAGGED_UPDATE_STATUS = "/Store/TagSet/TaggedItem/UpdateStatus/"

export function getTaggedItemList() {
    return Axios.get(TAGGEDITEM_LIST);
}
export function TaggedUpdateStatus(id, data) {
    return Axios.patch(TAGGED_UPDATE_STATUS + id + "/", data);
}
//Tags
export const TAGS_STORE_LIST = "/Store/Tagset/Tags/List/?"
export const TAGS_STORE_GET = "/Store/Tagset/Tags/Get/"
export const TAGS_STORE_REMOVE = "/Store/Tagset/Tags/Remove/"
export const TAGS_STORE_ADD = "/Store/Tagset/Tags/Add/"
export const TAGS_STORE_UPDATE = "/Store/Tagset/Tags/Update/"

export function getTagsList() {
    return Axios.get(TAGS_STORE_LIST)
}
export function getTags(id) {
    return Axios.get(TAGS_STORE_GET + id + "/")
}
export function deleteTagsStore(id) {
    return Axios.delete(TAGS_STORE_REMOVE + id + "/");
}
export function postTagsStore(data) {
    return Axios.post(TAGS_STORE_ADD, data)
}
export function patchTagsStore(id, data) {
    return Axios.patch(TAGS_STORE_UPDATE + id + "/", data)
}
// product Tag
export const TAG_PRODUCT_ADD = "/Store/TagSet/Tag/Product/Add/"
export const TAG_PRODUCT_LIST = "/Store/TagSet/TaggedItem/List/?"

export function postTagProduct(data) {
    return Axios.post(TAG_PRODUCT_ADD, data)
}
export function getTagProductList() {
    return Axios.get(TAG_PRODUCT_LIST)
}

//Offer Tag
export const TAG_OFFER_ADD = "/Store/TagSet/Tag/Offer/Add/"
export const TAG_OFFER_LIST = "/Store/TagSet/TaggedItem/List/?"

export function postTagOffer(data) {
    return Axios.post(TAG_OFFER_ADD, data)
}
export function getTagOfferList() {
    return Axios.get(TAG_OFFER_LIST)
}
// category tag
export const TAG_CATEGORY_LIST = "/Store/TagSet/TaggedItem/List/?"
export const TAG_CATEGORY_ADD = "/Store/TagSet/Tag/Category/Add"
export function postTagCategory(data) {
    return Axios.post(TAG_CATEGORY_ADD, data)
}
export function getTagCategoryList() {
    return Axios.get(TAG_CATEGORY_LIST)
}

//order review
export const ORDER_REVIEW_LIST = "/Store/Order/Review/List/?"

export function StoreOrderReviewList() {
    return Axios.get(STORE_ORDER_REVIEW_LIST)
}

export const ORDER_REVIEW_GET = "/Store/Order/Review/Get/"
export function getOrderReview(id) {
    return Axios.get(ORDER_REVIEW_GET + id + "/")
}

export const ORDER_REVIEW_UPDATE = "/Store/Order/Review/Update/"
export function PatchOrderReview(id, data) {
    return Axios.get(ORDER_REVIEW_UPDATE + id + "/", data)
}
export const OREDER_REVIEW_ADD = "/Store/Order/Review/Add/"
export function StoreOrderReviewAdd(data) {
    return Axios.post(OREDER_REVIEW_ADD, data)
}
//Category based product 

export const STORE_PRODUCT_LIST_BY_CATEGORY = "/Store/Product/ListByCategory/";

export function getProductListByCategory(id, search){
    if(search){
        return Axios.get(STORE_PRODUCT_LIST_BY_CATEGORY+id+"/?page_size=10000&search_value="+ search)
    }else{
        return Axios.get(STORE_PRODUCT_LIST_BY_CATEGORY+id+"/?page_size=10000")
    }
}

// product 
export const STORE_PRODUCT_LIST = "/Store/Product/List/";
export const STORE_PRODUCT_GET = "/Store/Product/Get/";
export const STORE_PRODUCT_ADD = "/Store/Product/Add/";
export const STORE_PRODUCT_UPDATE = "/Store/Product/Update/";
export const STORE_PRODUCT_REMOVE = "/Store/Product/Remove/";
export const Store_Product_Remove_Product_Logo = "/Store/Product/RemoveProductLogo/";

export function getStoreProductList(Query) {
    return Axios.get(STORE_PRODUCT_LIST + Query);
}
// 
export function getStoreProduct(id) {
    return Axios.get(STORE_PRODUCT_GET + id + "/");
}

export function postStoreProduct(data) {
    return Axios.post(STORE_PRODUCT_ADD, data);
}
export function patchStoreProduct(id, data) {
    return Axios.patch(STORE_PRODUCT_UPDATE + id + "/", data);
}
export function deleteStoreProduct(id) {
    return Axios.delete(STORE_PRODUCT_REMOVE + id + "/");
}

export function postStoreProductRemoveProductLogo(data) {
    return Axios.delete(Store_Product_Remove_Product_Logo+data+"/");
}

export const STORE_PRODUCT_GET_CATEGORY_HIERARCHY = "/Store/Product/GetCategoryHierarchy/";
export function getStoreProductCategoryHierarchy() {
    return Axios.get(STORE_PRODUCT_GET_CATEGORY_HIERARCHY);
}


export const STORE_PRODUCT_GET_PRODUCT_TYPES = "/Store/Product/GetProductTypes/";
export const STORE_PRODUCT_GET_PRODUCT_DETAILS = "/Store/Product/GetProductDetails/";

export function getStoreProducTypes() {
    return Axios.get(STORE_PRODUCT_GET_PRODUCT_TYPES);
}

export function getStoreProductGetProductDetails(id) {
    return Axios.get(STORE_PRODUCT_GET_PRODUCT_DETAILS + id + "/");
}


// product variant
export const STORE_VARIANT_GET = "/Store/Product/Variant/Get/";
export const STORE_VARIANT_PRICE_GET = "/Store/Product/Variant/price/Get/";
export const STORE_VARIANT_REMOVE_PRODUCT_LOGO = "/Store/Product/Variant/RemoveLogo/";

export function getStoreProductVariant(id) {
    return Axios.get(STORE_VARIANT_GET + id + "/");
}

export function getStoreProductVariantPrice(id) {
    return Axios.get(STORE_VARIANT_PRICE_GET + id + "/");
}

export function postStoreProductVariantRemoveVariantLogo(data) {
    return Axios.delete(STORE_VARIANT_REMOVE_PRODUCT_LOGO+data+"/");
}

// Category
export const STORE_PRODUCT_CATEGORY_LIST = "/Store/Product/Category/List/"
export const STORE_PRODUCT_CATEGORY_GET = "/Store/Product/Category/Get/"
export const STORE_PRODUCT_CATEGORY_ADD = "/Store/Product/Category/Add/"
export const STORE_PRODUCT_CATEGORY_UPDATE = "/Store/Product/Category/Update/"
export const STORE_PRODUCT_CATEGORY_REMOVE = "/Store/Product/Category/Remove/"
export const STORE_PRODUCT_REMOVECATEGORY_LOGO = "/Store/Product/RemoveCategoryLogo/"

export function getStoreProductRemoveCategoryLogo(id) {
    return Axios.delete(STORE_PRODUCT_REMOVECATEGORY_LOGO+ id +"/");
}

export function getStoreProductCategoryList() {
    return Axios.get(STORE_PRODUCT_CATEGORY_LIST);
}

export function getStoreProductCategory(id) {
    return Axios.get(STORE_PRODUCT_CATEGORY_GET + id + "/");
}

export function postStoreProductCategory(data, config) {
    return Axios.post(STORE_PRODUCT_CATEGORY_ADD, data, config);
}
export function patchStoreProductCategory(id, data, config) {
    return Axios.patch(STORE_PRODUCT_CATEGORY_UPDATE + id + "/", data, config);
}
export function deleteStoreProductCategory(id) {
    return Axios.delete(STORE_PRODUCT_CATEGORY_REMOVE + id + "/");
}

//dashboard order statices 

export const STORE_ORDER_STATISTICS = "/Store/Order/Statistics/"
export function getStoreOderStatics() {
    return Axios.get(STORE_ORDER_STATISTICS);
}

// dashboard API's

export const STATISTICS_ORDER_STATISTICS = "/Statistics/Order/statistics"
export const STATISTICS_ORDER_STATISTICSGRAPH = "/Statistics/Order/statisticsgraph"
export const STATISTICS_ORDER_TIMINGGRAPH = "/Statistics/Order/timing_graph"
export const STATISTICS_USER_SIGNUP_STATISTICSGRAPH = "/Statistics/User/signupstatisticsgraph"
export const STATISTICS_STORE_CATEGORY_STATISTICS = "/Statistics/Store/category_statistics"
export const STATISTICS_STORE_PRODUCT_STATISTICS = "/Statistics/Store/product_statistics"

export function postStatisticsOderStatistics(data) {
    return Axios.post(STATISTICS_ORDER_STATISTICS,data);
}

export function postStatisticsOderStatisticsGraph(data) {
    return Axios.post(STATISTICS_ORDER_STATISTICSGRAPH,data);
}

export function getStatisticsOderTimingGraph(query) {
    return Axios.get(STATISTICS_ORDER_TIMINGGRAPH + query);
}

export function getStatisticsUserSignupStatisticsGraph(query) {
    return Axios.get(STATISTICS_USER_SIGNUP_STATISTICSGRAPH + query);
}

export function getStatisticsStoreCategoryStatistics(query) {
    return Axios.get(STATISTICS_STORE_CATEGORY_STATISTICS + query);
}

export function getStatisticsStoreProductStatistics(query) {
    return Axios.get(STATISTICS_STORE_PRODUCT_STATISTICS + query);
}

// order 
// Category
export const STORE_ORDER_LIST = "/Store/Order/List/"
export const STORE_ORDER_LIST_NEW ="/Store/Order/Orders/List/"
export const STORE_ORDER_GET_STATUSES = "/Store/Order/GetStatuses/"
export const STORE_ORDER_GET_DETAILS_BY_ADMIN = "/Store/Order/GetDetailsByAdmin/"
export const STORE_ORDER_GET_DETAILS = "/Store/Order/GetDetails/"
export const STORE_ORDER_UPDATE_STATUS = "/Store/Order/UpdateStatus/"
export const STORE_ORDER_ADD = "/Store/Product/Category/Add/"
export const STORE_ORDER_UPDATE = "/Store/Product/Category/Update/"
export const STORE_ORDER_REMOVE = "/Store/Product/Category/Remove/"

export function getOrderSList() {
    return Axios.get(STORE_ORDER_LIST);
}

export function getOrderSListNew() {
    return Axios.get(STORE_ORDER_LIST_NEW);
}

export function getOrderGetStatus() {
    return Axios.get(STORE_ORDER_GET_STATUSES);
}

export function getOrderDetailsByAdmin(id) {
    return Axios.get(STORE_ORDER_GET_DETAILS_BY_ADMIN + id + "/");
}

export function getOrderDetails(id) {
    return Axios.get(STORE_ORDER_GET_DETAILS + id + "/");
}
export function postStoreOrderUpdateStatus(data) {
    return Axios.post(STORE_ORDER_UPDATE_STATUS, data);
}

// export function patchStoreProductCategory(id, data) {
//   return Axios.patch(STORE_PRODUCT_CATEGORY_UPDATE + id + "/", data);
// }
// export function deleteStoreProductCategory(id) {
//   return Axios.delete(STORE_PRODUCT_CATEGORY_REMOVE + id + "/");
// }


// media offer 

export const STORE_OFFER_MEDIA_LIST_GALLARYKEY_TYPES = "/Store/Offer/Media/ListGalleryKeyTypes/"

export const ORG_TREE_NODE_MEDIA_LIST = "/Org/Tree/NodeMedia/List/";
// export const STORE_PRODUCT_CATEGORY_GET ="/Store/Product/Category/Get/"
export const ORG_TREE_NODE_MEDIA_ADD = "/Org/Tree/NodeMedia/Add/"
    // export const STORE_PRODUCT_CATEGORY_UPDATE ="/Store/Product/Category/Update/"
export const ORG_TREE_NODE_MEDIA_REMOVE = "/Org/Tree/NodeMedia/Remove/"

export function getStoreMediaListGalleryKeyTypes() {
    return Axios.get(STORE_OFFER_MEDIA_LIST_GALLARYKEY_TYPES);
}

// export function getStoreProductCategory(id) {
//   return Axios.get(STORE_PRODUCT_CATEGORY_GET + id + "/");
// }

export function postOrgTreeMediaAdd(data) {
    return Axios.post(ORG_TREE_NODE_MEDIA_ADD, data);
}
// export function patchStoreProductCategory(id, data) {
//   return Axios.patch(STORE_PRODUCT_CATEGORY_UPDATE + id + "/", data);
// }
export function deleteOrgNodeMedia(id) {
    return Axios.delete(ORG_TREE_NODE_MEDIA_REMOVE + id + "/");
}

// get node list 
export const ORG_TREE_NODE_LIST = "Org/Tree/Node/List";

//order statisticcs
export const STORE_ORDER_STATISTICES = "/Store/Order/Statistics/"



// DeliveryAmount
export const STORE_DELIVERY_AMOUNT_LIST = "/Store/Delivery/Amount/List/"
export const STORE_DELIVERY_AMOUNT_GET = "/Store/Delivery/Amount/Get/"
export const STORE_DELIVERY_AMOUNT_ADD = "/Store/Delivery/Amount/Add/"
export const STORE_DELIVERY_AMOUNT_UPDATE = "/Store/Delivery/Amount/Update/"
export const STORE_DELIVERY_AMOUNT_REMOVE = "/Store/Delivery/Amount/Remove/"

export function getStoreDeliveryAmountList() {
    return Axios.get(STORE_DELIVERY_AMOUNT_LIST);
}

export function getStoreDeliveryAmountGet(id) {
    return Axios.get(STORE_DELIVERY_AMOUNT_GET + id + "/");
}

export function postDeliveryAmountAdd(data) {
    return Axios.post(STORE_DELIVERY_AMOUNT_ADD, data);
}

export function patchDeliveryAmountUpdate(id, data) {
    return Axios.patch(STORE_DELIVERY_AMOUNT_UPDATE + id + "/", data);
}

export function deleteDeliveryAmount(id) {
    return Axios.delete(STORE_DELIVERY_AMOUNT_REMOVE + id + "/");
}

// DeliverySlot
export const STORE_DELIVERY_SLOT_LIST = "/Store/Delivery_Slot/List/"
export const STORE_DELIVERY_SLOT_GET = "/Store/Delivery_Slot/Get/"
export const STORE_DELIVERY_SLOT_ADD = "/Store/Delivery_Slot/Add/"
export const STORE_DELIVERY_SLOT_UPDATE = "/Store/Delivery_Slot/Update/"
export const STORE_DELIVERY_SLOT_REMOVE = "/Store/Delivery_Slot/Remove/"

export function getStoreDeliverySlotList() {
    return Axios.get(STORE_DELIVERY_SLOT_LIST);
}

export function getStoreDeliverySlotGet(id) {
    return Axios.get(STORE_DELIVERY_SLOT_GET + id + "/");
}

export function postDeliverySlotAdd(data) {
    return Axios.post(STORE_DELIVERY_SLOT_ADD, data);
}

export function patchDeliverySlotUpdate(id, data) {
    return Axios.patch(STORE_DELIVERY_SLOT_UPDATE + id + "/", data);
}

export function deleteDeliverySlot(id) {
    return Axios.delete(STORE_DELIVERY_SLOT_REMOVE + id + "/");
}

// product Suppliers
export const STORE_SUPPLIER_LIST = "/Store/Supplier/List/"
export const STORE_SUPPLIER_GET = "/Store/Supplier/Get/"
export const STORE_SUPPLIER_ADD = "/Store/Supplier/Add/"
export const STORE_SUPPLIER_UPDATE = "/Store/Supplier/Update/"
export const STORE_SUPPLIER_REMOVE = "/Store/Supplier/Remove/"

export function getStoreSupplierList() {
    return Axios.get(STORE_SUPPLIER_LIST);
}

export function getStoreSupplierGet(id) {
    return Axios.get(STORE_SUPPLIER_GET + id + "/");
}

export function postSupplierAdd(data) {
    return Axios.post(STORE_SUPPLIER_ADD, data)
}

export function patchSupplierUpdate(id, data) {
    return Axios.patch(STORE_SUPPLIER_UPDATE + id + "/", data);
}

export function deleteSupplierRemove(id) {
    return Axios.delete(STORE_SUPPLIER_REMOVE + id + "/");
}


// product proposition
export const STORE_PRODUCT_PROPORTION_LIST = "/Store/Product/Proportion/List/"
export const STORE_PRODUCT_PROPORTION_GET = "/Store/Product/Proportion/Get/"
export const STORE_PRODUCT_PROPORTION_ADD = "/Store/Product/Proportion/Add/"
export const STORE_PRODUCT_PROPORTION_UPDATE = "/Store/Product/Proportion/Update/"
export const STORE_PRODUCT_PROPORTION_REMOVE = "/Store/Product/Proportion/Remove/"
export const STORE_PRODUCT_PROPORTION_GROUPS_LISTBY_PRODUCT = "/Store/Product/ProportionGroups/ListByProduct/"
export const ORG_SERVICES_VARIANTS_LIST = "/Org/Services/Variants/List/"

export function getStoreProductProportionList(id, Query) {
    return Axios.get(STORE_PRODUCT_PROPORTION_LIST + "?proportion_group_id=" + id + Query);
}
export function getStoreProductProportionListByProductGet(id) {
    return Axios.get(STORE_PRODUCT_PROPORTION_GROUPS_LISTBY_PRODUCT + id + "/");
}

export function getStoreProductProportionGet(id) {
    return Axios.get(STORE_PRODUCT_PROPORTION_GET + id + "/");
}

export function postProductProportionAdd(data) {
    return Axios.post(STORE_PRODUCT_PROPORTION_ADD, data);
}

export function patchProductProportionUpdate(id, data) {
    return Axios.patch(STORE_PRODUCT_PROPORTION_UPDATE + id + "/", data);
}

export function deleteProductProportion(id) {
    return Axios.delete(STORE_PRODUCT_PROPORTION_REMOVE + id + "/");
}

export function servicesVariantsList() {
    return Axios.get(ORG_SERVICES_VARIANTS_LIST);
}
// ProportionGroup 

export const STORE_PRODUCT_PROPORTION_GROUP_LIST = "/Store/Product/ProportionGroup/List/"
export const STORE_PRODUCT_PROPORTION_GROUP_GET = "/Store/Product/ProportionGroup/Get/"
export const STORE_PRODUCT_PROPORTION_GROUP_ADD = "/Store/Product/ProportionGroup/Add/"
export const STORE_PRODUCT_PROPORTION_GROUP_UPDATE = "/Store/Product/ProportionGroup/Update/"
export const STORE_PRODUCT_PROPORTION_GROUP_REMOVE = "/Store/Product/ProportionGroup/Remove/"
export const STORE_PRODUCT_PROPORTION_GROUP_LIST_BY_CATEGORY = "/Store/Product/ProportionGroup/ListByCategory/"
export function getStoreProductProportionGroupList(Query) {
    return Axios.get(STORE_PRODUCT_PROPORTION_GROUP_LIST + Query);
}

export function getStoreProductProportionGroupGet(id) {
    return Axios.get(STORE_PRODUCT_PROPORTION_GROUP_GET + id + "/");
}

export function postProductProportionGroupAdd(data) {
    return Axios.post(STORE_PRODUCT_PROPORTION_GROUP_ADD, data);
}

export function patchProductProportionGroupUpdate(id, data) {
    return Axios.patch(STORE_PRODUCT_PROPORTION_GROUP_UPDATE + id + "/", data);
}

export function deleteProductProportionGroup(id) {
    return Axios.delete(STORE_PRODUCT_PROPORTION_GROUP_REMOVE + id + "/");
}

// VoucherGroup

export const STORE_OFFER_VOUCHERGROUP_GET_VOUCHER_BY_VOUCHER_GROUP = "/Store/Offer/VoucherGroup/GetVoucherByVoucherGroup/"
export const STORE_OFFER_VOUCHER_GROUP_GET_VOUCHER_PATTERNS = "/Store/Offer/VoucherGroup/GetVoucherPatterns/"

export const STORE_OFFER_VOUCHER_GROUP_LIST = "/Store/Offer/VoucherGroup/List/"
export const STORE_OFFER_VOUCHER_GROUP_GET = "/Store/Offer/VoucherGroup/Get/"
export const STORE_OFFER_VOCHER_GROUP_ADD = "/Store/Offer/VoucherGroup/Add/"
export const STORE_OFFER_VOCHER_GROUP_UPDATE = "/Store/Offer/VoucherGroup/Update/"
export const STORE_OFFER_VOUCHER_GROUP_REMOVE = "/Store/Offer/VoucherGroup/Remove/"

export function getStoreOfferVoucherGroupGetVoucherPatterns() {
    return Axios.get(STORE_OFFER_VOUCHER_GROUP_GET_VOUCHER_PATTERNS);
}
export function getStoreOfferVoucherGroupList() {
    return Axios.get(STORE_OFFER_VOUCHER_GROUP_LIST);
}

export function getStoreOfferVoucherGroupGet(id) {
    return Axios.get(STORE_OFFER_VOUCHER_GROUP_GET + id + "/");
}

export function postStoreOfferVoucherGroupAdd(data) {
    return Axios.post(STORE_OFFER_VOCHER_GROUP_ADD, data);
}
export function patchStoreOfferVoucherGroupUpdate(id, data) {
    return Axios.patch(STORE_OFFER_VOCHER_GROUP_UPDATE + id + "/", data);
}

export function deleteStoreOfferVoucherGroupRemove(id) {
    return Axios.delete(STORE_OFFER_VOUCHER_GROUP_REMOVE + id + "/");
}


// voucher 
export const STORE_VOUCHER_LIST = "/Store/Voucher/List/"
export const STORE_VOUCHER_GET = "/Store/Voucher/Get/"
export const STORE_VOUCHER_ADD = "/Store/Voucher/Add/"
export const STORE_VOUCHER_UPDATE = "/Store/Voucher/Update/"
export const STORE_VOUCHER_REMOVE = "/Store/Voucher/Remove/"

export function getVoucherList() {
    return Axios.get(STORE_VOUCHER_LIST);
}

export function getStoreVoucherGet(id) {
    return Axios.get(STORE_VOUCHER_GET + id + "/");
}

export function postStoreVoucherAdd(data) {
    return Axios.post(STORE_VOUCHER_ADD, data);
}
export function patchStoreVoucherUpdate(id, data) {
    return Axios.patch(STORE_VOUCHER_UPDATE + id + "/", data);
}
export function deleteStoreVoucherRemove(id) {
    return Axios.delete(STORE_VOUCHER_REMOVE + id + "/");
}

// product addon group 
export const STORE_PRODUCT_ADDON_GROUP_LIST = "/Store/Product/AddonGroup/List/"
export const STORE_PRODUCT_ADDON_GROUP_GET = "/Store/Product/AddonGroup/Get/"
export const STORE_PRODUCT_ADDON_GROUP_ADD = "/Store/Product/AddonGroup/Add/"
export const STORE_PRODUCT_ADDON_GROUP_UPDATE = "/Store/Product/AddonGroup/Update/"
export const STORE_PRODUCT_ADDON_GROUP_REMOVE = "/Store/Product/AddonGroup/Remove/"

export function getStoreProductAddonGroupList() {
    return Axios.get(STORE_PRODUCT_ADDON_GROUP_LIST);
}

export function getStoreProductAddonGroupGet(id) {
    return Axios.get(STORE_PRODUCT_ADDON_GROUP_GET + id + "/");
}

export function postStoreProductAddonGroupAdd(data) {
    return Axios.post(STORE_PRODUCT_ADDON_GROUP_ADD, data);
}
export function patchStoreProductAddonGroupUpdate(id, data) {
    return Axios.patch(STORE_PRODUCT_ADDON_GROUP_UPDATE + id + "/", data);
}
export function deleteStoreProductAddonGroupRemove(id) {
    return Axios.delete(STORE_PRODUCT_ADDON_GROUP_REMOVE + id + "/");
}


//Product Unit
export const STORE_PRODUCT_UNITS = "/Store/Product/units/"
export function getStoreProductUnit() {
    return Axios.get(STORE_PRODUCT_UNITS);
}


//Product Price
export const STORE_PRODUCT_PRICE_LIST = "Store/Product/Price/List/"
export function getStoreProductPriceList(id) {
    return Axios.get(STORE_PRODUCT_PRICE_LIST + "?product-id=" + id);
}

export const STORE_PRODUCT_PRICE_ADD = "/Store/Product/Price/Add/"
export function getStoreProductPriceAdd(data) {
    return Axios.post(STORE_PRODUCT_PRICE_ADD, data);
}

export const STORE_PRODUCT_VARIANT_ADD = "/Store/Product/Variant/Add/"
export function getStoreProductVariantAdd(data) {
    return Axios.post(STORE_PRODUCT_VARIANT_ADD, data)
}

export const STORE_PRODUCT_VARIANT_ADD_V2 = "Store/Product/Variant/Add/V2"
export function getStoreProductVariantAddVersion2(data) {
    return Axios.post(STORE_PRODUCT_VARIANT_ADD_V2, data)
}

export const STORE_PRODUCT_VARIANT_LIST = "/Store/Product/Variant/List/"
export function getStoreProductVariantList(data) {
    return Axios.get(STORE_PRODUCT_VARIANT_LIST + "?product_id=" + data);
}

export const STORE_PRODUCT_VARIANT_DETAILS_LIST = "/Store/Product/Variant/Details";
export function getStoreProductVariantDetailsList() {
    return Axios.get(STORE_PRODUCT_VARIANT_DETAILS_LIST);
}

export const STORE_PRODUCT_VARIANT_DETAILS_LIST_V2 = "/Store/Product/Variant/List/V2";
export function getStoreProductVariantDetailsListV2() {
    return Axios.get(STORE_PRODUCT_VARIANT_DETAILS_LIST_V2);
}

export const STORE_PRODUCT_VARIANT_PRICE_ADD = "/Store/Product/Variant/price/Add/"
export function postStoreProductVariantPriceAdd(data) {
    return Axios.post(STORE_PRODUCT_VARIANT_PRICE_ADD, data)
}

export const STORE_PRODUCT_VARIANT_PRICE_REMOVE = "/Store/Product/Variant/price/Remove/"

export function deleteStoreProductVariantPricetDelete(id) {
    return Axios.delete(STORE_PRODUCT_VARIANT_PRICE_REMOVE + id + "/");
}

export const STORE_PRODUCT_VARIANT_QUANTITY_ADD = "/Store/Product/Variant/Quantity/Add/"
export function postStoreProductVariantQuantityAdd(data) {
    return Axios.post(STORE_PRODUCT_VARIANT_QUANTITY_ADD, data)
}

export const STORE_PRODUCT_VARIANT_QUANTITY_UPDATE = "/Store/Product/Variant/Quantity/Update/"
export function patchStoreProductVariantQuantityUpdate(id, data) {
    return Axios.patch(STORE_PRODUCT_VARIANT_QUANTITY_UPDATE + id + "/", data)
}

export const STORE_PRODUCT_VARIANT_QUANTITY_REMOVE = "/Store/Product/Variant/Quantity/Remove/"
export function deleteStoreProductVarianQuantitytDelete(id) {
    return Axios.delete(STORE_PRODUCT_VARIANT_QUANTITY_REMOVE + id + "/");
}

export const STORE_PRODUCT_VARIANT_PROPORTION_ADD = "/Store/Product/Variant/Proportion/Add/"
export function postStoreProductVariantProportionAdd(data) {
    return Axios.post(STORE_PRODUCT_VARIANT_PROPORTION_ADD, data)
}
export const STORE_PRODUCT_VARIANT_PROPORTION_UPDATE = "/Store/Product/Variant/Proportion/Update/"



export function patchStoreProductVariantProportionUpdate(id, data) {
    return Axios.patch(STORE_PRODUCT_VARIANT_PROPORTION_UPDATE + id + "/", data)
}

export const STORE_PRODUCT_VARIANT_PROPORTION_REMOVE = "/Store/Product/Variant/Proportion/Remove/"

export function deleteStoreProductVariantProportionDelete(id) {

    return Axios.delete(STORE_PRODUCT_VARIANT_PROPORTION_REMOVE + id + "/");
}

export const STORE_PRODUCT_VARIANT_UPDATE = "/Store/Product/Variant/Update/"
export function patchStoreProductVariantUpdate(id, data) {
    return Axios.patch(STORE_PRODUCT_VARIANT_UPDATE + id + "/", data)
}

export const STORE_PRODUCT_VARIANT_UPDATE_V2 = "/Store/Product/Variant/Update/V2/"
export function patchStoreProductVariantUpdateVersion2(id, data) {
    return Axios.patch(STORE_PRODUCT_VARIANT_UPDATE_V2 + id + "/", data)
}

export const STORE_PRODUCT_VARIANT_DELETE = "/Store/Product/Variant/Remove/"
export function deleteStoreProductVariantDelete(id) {

    return Axios.delete(STORE_PRODUCT_VARIANT_DELETE + id + "/");
}

//Finance Tax
export const FINANCE_TAX_LABEL_LIST = "Store/Finance/Tax/Label/List/"
export function getFinanceTaxLabelList() {
    return Axios.get(FINANCE_TAX_LABEL_LIST);
}
export const FINANCE_TAX_LABEL_DELETE = "/Store/Finance/Tax/Label/Remove/"
export function getFinanceTaxLabelDelete(id) {
    return Axios.delete(FINANCE_TAX_LABEL_DELETE + id + "/");
}
//Taxs
export const TAXES = "Store/Finance/Tax/List/"
export function getTaxes(id) {
    return Axios.get(TAXES + "?tax-label-id=" + id);
}

// product banner 
export const STORE_PRODUCT_MEDIA_GET_BANNER_BY_PRODUCT_ID = "/Store/Product/Media/GetBannerByProductId/"
export const STORE_PRODUCT_MEDIA_BANNER_ADD = "/Store/Product/Media/Banner/Add/"
export const STORE_PRODUCT_MEDIA_BANNER_REMOVE = "/Store/Product/Media/Banner/Remove/"

export function getStoreProductMediaGetBanner(id) {
    return Axios.get(STORE_PRODUCT_MEDIA_GET_BANNER_BY_PRODUCT_ID + id + "/");
}
export function postStoreProductMediaBannerAdd(data) {
    return Axios.post(STORE_PRODUCT_MEDIA_BANNER_ADD, data);
}
export function deleteStoreProductMediaBanner(id) {
    return Axios.delete(STORE_PRODUCT_MEDIA_BANNER_REMOVE + id + "/");
}

// node setting 
export const ORG_TREE_NODE_SETTING_LIST = "/Org/Tree/NodeSetting/List"

// offer 
export const STORE_OFFER_LIST = "/Store/Offer/List/"

export const STORE_OFFER_GET = "/Store/Offer/Get/"

export const STORE_OFFER_ADD = "/Store/Offer/Add/"

export const STORE_OFFER_UPDATE = "/Store/Offer/Update/"

export const STORE_OFFER_REMOVE = "/Store/Offer/Remove/"

export function getStoreOfferGet(id) {
    return Axios.get(STORE_OFFER_GET + id + "/");
}
export function postStoreOfferAdd(data) {
    return Axios.post(STORE_OFFER_ADD, data);
}
export function postStoreOfferUpdate(id, data) {
    return Axios.patch(STORE_OFFER_UPDATE + id + "/", data);
}
export function deleteStoreOfferRemove(id) {
    return Axios.delete(STORE_OFFER_REMOVE + id + "/");
}
export function listSoreOffer() {
    return Axios.get(STORE_OFFER_LIST)
}
// offer schema 

export const STORE_OFFER_LIST_BY_PRODUCT_ID = "/Store/Offer/ListByProductId/"

export const STORE_OFFER_SCHEMA_LIST = "/Store/Offer/Schema/List/"

export const STORE_OFFER_SCHEMA_GET = "/Store/Offer/Schema/Get/"

export const STORE_OFFER_SCHEMA_ADD = "/Store/Offer/Schema/Add/"

export const STORE_OFFER_SCHEMA_UPDATE = "/Store/Offer/Schema/Update/"

export const STORE_OFFER_SCHEMA_REMOVE = "/Store/Offer/Schema/Remove/"

export function getStoreOfferSchemaGet(id) {
    return Axios.get(STORE_OFFER_SCHEMA_GET + id + "/");
}
export function postStoreOfferSchemaAdd(data) {
    return Axios.post(STORE_OFFER_SCHEMA_ADD, data);
}
export function postStoreOfferSchemaUpdate(id, data) {
    return Axios.patch(STORE_OFFER_SCHEMA_UPDATE + id + "/", data);
}
export function deleteStoreOfferSchemaRemove(id) {
    return Axios.delete(STORE_OFFER_SCHEMA_REMOVE + id + "/");
}

// offer draft 

export const STORE_OFFER_CREATE_DRAFT = "/Store/Offer/CreateDraft/"
export const STORE_OFFER_SECTION_REMOVE = "/Store/Offer/RemoveOfferDraftLogo/"
export const STORE_OFFER_UPDATE_DRAFT = "/Store/Offer/UpdateDraft/"
export const STORE_OFFER_SUBMIT_DRAFT = "/Store/Offer/SubmitDraft/"

// export function postStoreOfferCreateDraft(data) {
//     console.log("offerapidata",data)
//     let datas = {active:1,
//         claim_times:data?.claim_times,
//         logo:data?.logo,
//         scheme : data?.scheme,
//         slug : data?.slug,
//         title : data?.title
//     }
//     console.log("datasvalue",datas)
//     return Axios.post(STORE_OFFER_CREATE_DRAFT, datas);
// }
export function postStoreOfferCreateDraft(data) {
   
    let formData = new FormData();
    formData.append('active', 1); 
    formData.append('claim_times',parseInt( data?.claim_times )|| ''); 
    formData.append('scheme', data?.scheme.toString() || ''); 
    formData.append('slug', data?.slug || ''); 
    formData.append('title', data?.title.toString()|| ''); 
    if (data?.logo) {
        formData.append('logo', data.logo);
    }
   
    return Axios.post(STORE_OFFER_CREATE_DRAFT, formData, {
        headers: {
            'Content-Type': 'multipart/form-data', 
        },
    });
}

export function postStoreOfferRemoveDraft(id){
    return Axios.delete(STORE_OFFER_SECTION_REMOVE + id +"/")
}
export function postStoreOfferSubmitDraft(data) {
    return Axios.post(STORE_OFFER_SUBMIT_DRAFT, data);
}
export function postStoreOfferUpdateDraft(id, data) {
    let formData = new FormData();
    formData.append('active', 1); 
    formData.append('claim_times',parseInt( data?.claim_times )|| ''); 
    formData.append('scheme', data?.scheme.toString() || ''); 
    formData.append('slug', data?.slug || ''); 
    formData.append('title', data?.title.toString()|| ''); 
    if (data?.logo) {
        formData.append('logo', data.logo);
    } console.log("values", formData);
    return Axios.patch(STORE_OFFER_UPDATE_DRAFT + id + "/", formData, {
        headers: {
            'Content-Type': 'multipart/form-data', 
        },
    });
    // return Axios.patch(STORE_OFFER_UPDATE_DRAFT + id + "/", data);
}

// offer platform 
export const STORE_OFFER_LIST_PLATFORM_TYPE = "/Store/Offer/ListPlatformType/"
export function getStoreOfferListPlatform() {
    return Axios.get(STORE_OFFER_LIST_PLATFORM_TYPE);
}
export const STORE_OFFER_PLATFORM_ADD = "/Store/Offer/Platform/Add/"



// offer tag 

export const STORE_OFFER_TAG_ADD = "/Store/Offer/Tag/Add/"

// offer category 

export const STORE_OFFER_CATEGORY_LIST = "/Store/Offer/Category/List/"
export const STORE_OFFER_CATEGORY_GET = "/Store/Offer/Category/Get/"
export const STORE_OFFER_CATEGORY_ADD = "/Store/Offer/Category/Add/"
export const STORE_OFFER_CATEGORY_UPDATE = "/Store/Offer/Category/Update"
export const STORE_OFFER_CATEGORY_REMOVE = "/Store/Offer/Category/Remove/"

export function getOfferCategoryList() {
    return Axios.get(STORE_OFFER_CATEGORY_LIST);
}

export function getStoreOfferCategoryGet(id) {
    return Axios.get(STORE_OFFER_CATEGORY_GET + id + "/");
}

export function postStoreOfferCategoryAdd(data) {
    return Axios.post(STORE_OFFER_CATEGORY_ADD, data);
}
export function patchStoreOfferCategoryUpdate(id, data) {
    return Axios.patch(STORE_OFFER_CATEGORY_UPDATE + id + "/", data);
}
export function deleteStoreOfferCategoryRemove(id) {
    return Axios.delete(STORE_OFFER_CATEGORY_REMOVE + id + "/");
}

// offer product 
export const STORE_OFFER_PRODUCT_LIST = "/Store/Offer/Product/List/"
export const STORE_OFFER_PRODUCT_GET = "/Store/Offer/Product/Get/"
export const STORE_OFFER_PRODUCT_ADD = "/Store/Offer/Product/Add/"
export const STORE_OFFER_PRODUCT_UPDATE = "/Store/Offer/Product/Update/"
export const STORE_OFFER_PRODUCT_REMOVE = "/Store/Offer/Product/Remove/"
    // export const STORE_OFFER_LIST_BY_PRODUCT_ID ="/Store/Offer/ListByProductId/"


export function getOfferProductList() {
    return Axios.get(STORE_OFFER_PRODUCT_LIST);
}

export function getStoreOfferProductGet(id) {
    return Axios.get(STORE_OFFER_PRODUCT_GET + id + "/");
}

export function postStoreOfferProductAdd(data) {
    return Axios.post(STORE_OFFER_PRODUCT_ADD, data);
}
export function patchStoreOfferProductUpdate(id, data) {
    return Axios.patch(STORE_OFFER_PRODUCT_UPDATE + id + "/", data);
}
export function deleteStoreOfferProductRemove(id) {
    return Axios.delete(STORE_OFFER_PRODUCT_REMOVE + id + "/");
}

// offer inclusive update 
export const STORE_OFFER_INCLUSIVE_UPDATE = "/Store/Offer/InclusiveUpdate/"


// user list 

export const ORG_USER_LIST = "/Org/User/List/"
export const ORG_USER_LIST_FETCH = "/Org/User/ListFetch/"


//offer beneficiary 
export const STORE_OFFER_LIST_BENEFI_TYPES = "/Store/Offer/ListBenefitTypes/"
export const STORE_OFFER_BENEFICIARY_LIST = "/Store/Offer/Beneficiary/List/"
export const STORE_OFFER_BENEFICIARY_GET = "/Store/Offer/Beneficiary/Get/"
export const STORE_OFFER_BENEFICIARY_ADD = "/Store/Offer/Beneficiary/Add/"
export const STORE_OFFER_BENEFICIARY_UPDATE = "/Store/Offer/Beneficiary/Update/"
export const STORE_OFFER_BENEFICIARY_REMOVE = "/Store/Offer/Beneficiary/Remove/"

export function getOfferListBenefitTypes() {
    return Axios.get(STORE_OFFER_LIST_BENEFI_TYPES);
}

export function getOfferBeneficiaryList() {
    return Axios.get(STORE_OFFER_BENEFICIARY_LIST);
}

export function getStoreOfferBeneficiaryGet(id) {
    return Axios.get(STORE_OFFER_BENEFICIARY_GET + id + "/");
}

export function postStoreOfferBeneficiaryAdd(data) {
    return Axios.post(STORE_OFFER_BENEFICIARY_ADD, data);
}
export function patchStoreOfferBeneficiaryUpdate(id, data) {
    return Axios.patch(STORE_OFFER_BENEFICIARY_UPDATE + id + "/", data);
}
export function deleteStoreOfferBeneficiaryRemove(id) {
    return Axios.delete(STORE_OFFER_BENEFICIARY_REMOVE + id + "/");
}
// offer voucher 
export const STORE_OFFER_VOUCHER_LIST = "/Store/Offer/Voucher/List/"
export const STORE_OFFER_VOUCHER_GET = "/Store/Offer/Voucher/Get/"
export const STORE_OFFER_VOUCHER_ADD = "/Store/Offer/Voucher/Add/"
export const STORE_OFFER_VOUCHER_UPDATE = "/Store/Offer/Voucher/Update/"
export const STORE_OFFER_VOUCHER_REMOVE = "/Store/Offer/Voucher/Remove/"

export function getOfferVoucherList() {
    return Axios.get(STORE_OFFER_VOUCHER_LIST);
}

export function getStoreOfferVoucherGet(id) {
    return Axios.get(STORE_OFFER_VOUCHER_GET + id + "/");
}

export function postStoreOfferVoucherAdd(data) {
    return Axios.post(STORE_OFFER_VOUCHER_ADD, data);
}
export function patchStoreOfferVoucherUpdate(id, data) {
    return Axios.patch(STORE_OFFER_VOUCHER_UPDATE + id + "/", data);
}
export function deleteStoreOfferVoucherRemove(id) {
    return Axios.delete(STORE_OFFER_VOUCHER_REMOVE + id + "/");
}
// offer Qty condition 
export const STORE_OFFER_QTY_CONDITION_LIST = "/Store/Offer/QtyCondition/List/"
export const STORE_OFFER_QTY_CONDITION_GET = "/Store/Offer/QtyCondition/Get/"
export const STORE_OFFER_QTY_CONDITION_ADD = "/Store/Offer/QtyCondition/Add/"
export const STORE_OFFER_QTY_CONDITION_UPDATE = "/Store/Offer/QtyCondition/Update/"
export const STORE_OFFER_QTY_CONDITION_REMOVE = "/Store/Offer/QtyCondition/Remove/"

export function getOfferQtyConditionList() {
    return Axios.get(STORE_OFFER_QTY_CONDITION_LIST);
}

export function getStoreOfferQtyConditionGet(id) {
    return Axios.get(STORE_OFFER_QTY_CONDITION_GET + id + "/");
}

export function postStoreOfferQtyConditionAdd(data) {
    return Axios.post(STORE_OFFER_QTY_CONDITION_ADD, data);
}
export function patchStoreOfferQtyConditionUpdate(id, data) {
    return Axios.patch(STORE_OFFER_QTY_CONDITION_UPDATE + id + "/", data);
}
export function deleteStoreOfferQtyConditionRemove(id) {
    return Axios.delete(STORE_OFFER_QTY_CONDITION_REMOVE + id + "/");
}

// offer total condition 
export const STORE_OFFER_TOTAL_CONDITION_LIST = "/Store/Offer/TotalCondition/List/"
export const STORE_OFFER_TOTAL_CONDITION_GET = "/Store/Offer/TotalCondition/Get/"
export const STORE_OFFER_TOTAL_CONDITION_ADD = "/Store/Offer/TotalCondition/Add/"
export const STORE_OFFER_TOTAL_CONDITION_UPDATE = "/Store/Offer/TotalCondition/Update/"
export const STORE_OFFER_TOTAL_CONDITION_REMOVE = "/Store/Offer/TotalCondition/Remove/"

export function getOfferTotalConditionList() {
    return Axios.get(STORE_OFFER_TOTAL_CONDITION_LIST);
}

export function getStoreOfferTotalConditionGet(id) {
    return Axios.get(STORE_OFFER_TOTAL_CONDITION_GET + id + "/");
}

export function postStoreOfferTotalConditionAdd(data) {
    return Axios.post(STORE_OFFER_TOTAL_CONDITION_ADD, data);
}
export function patchStoreOfferTotalConditionUpdate(id, data) {
    return Axios.patch(STORE_OFFER_TOTAL_CONDITION_UPDATE + id + "/", data);
}
export function deleteStoreOfferTotalConditionRemove(id) {
    return Axios.delete(STORE_OFFER_TOTAL_CONDITION_REMOVE + id + "/");
}
// offer change api name 
export const STORE_OFFER_PRODUCT_ADD_AND_REMOVE = "/Store/Offer/Product/Add/"
export const STORE_OFFER_CATEGOTY_ADD_AND_REMOVE = "/Store/Offer/Category/Add/"
export const STORE_OFFER_BENEFICIARY_ADD_AND_REMOVE = "/Store/Offer/Beneficiary/Add/"
export const STORE_OFFER_QTY_CONDITION = "/Store/Offer/QtyCondition/"
export const STORE_OFFER_TOTAL_CONDITION = "/Store/Offer/TotalCondition/"


// review types 

// product review 

export const STORE_PRODUCT_REVIEW_LIST = "/Store/Product/Review/List/"
export const STORE_PRODUCT_REVIEW_GET = "/Store/Product/Review/Get/"
export const STORE_PRODUCT_REVIEW_ADD = "/Store/Product/Review/Add/"
export const STORE_PRODUCT_REVIEW_UPDATE = "/Store/Product/Review/Update/"
export const STORE_PRODUCT_REVIEW_REMOVE = "/Store/Product/Review/Remove/"

export function getStoreProductReviewList() {
    return Axios.get(STORE_PRODUCT_REVIEW_LIST);
}

export function getStoreProductReviewGet(id) {
    return Axios.get(STORE_PRODUCT_REVIEW_GET + id + "/");
}

export function postStoreProductReviewAdd(data) {
    return Axios.post(STORE_PRODUCT_REVIEW_ADD, data);
}
export function patchStoreProductReviewUpdate(id, data) {
    return Axios.patch(STORE_PRODUCT_REVIEW_UPDATE + id + "/", data);
}
export function deleteStoreProductReviewRemove(id) {
    return Axios.delete(STORE_PRODUCT_REVIEW_REMOVE + id + "/");
}

// order review 

export const STORE_ORDER_REVIEW_LIST = "/Store/Order/Review/List/"
export const STORE_ORDER_REVIEW_GET = "/Store/Order/Review/Get/"
export const STORE_ORDER_REVIEW_ADD = "/Store/Order/Review/Add/"
export const STORE_ORDER_REVIEW_UPDATE = "/Store/Order/Review/Update/"
export const STORE_ORDER_REVIEW_REMOVE = "/Store/Order/Review/Remove/"

export function getStoreOrderReviewList() {
    return Axios.get(STORE_ORDER_REVIEW_LIST);
}

export function getStoreOrderReviewGet(id) {
    return Axios.get(STORE_ORDER_REVIEW_GET + id + "/");
}

export function postStoreOrderReviewAdd(data) {
    return Axios.post(STORE_ORDER_REVIEW_ADD, data);
}
export function patchStoreOrderReviewUpdate(id, data) {
    return Axios.patch(STORE_ORDER_REVIEW_UPDATE + id + "/", data);
}
export function deleteStoreOrderReviewRemove(id) {
    return Axios.delete(STORE_ORDER_REVIEW_REMOVE + id + "/");
}

// branch review 

export const STORE_BRANCH_REVIEW_LIST = "/Store/BranchReview/List/"
export const STORE_BRANCH_REVIEW_GET = "/Store/BranchReview/Get/"
export const STORE_BRANCH_REVIEW_ADD = "/Store/BranchReview/Add/"
export const STORE_BRANCH_REVIEW_UPDATE = "/Store/BranchReview/Update/"
export const STORE_BRANCH_REVIEW_REMOVE = "/Store/BranchReview/Remove/"

export function getStoreBranchReviewList() {
    return Axios.get(STORE_BRANCH_REVIEW_LIST);
}

export function getStoreBranchReviewGet(id) {
    return Axios.get(STORE_BRANCH_REVIEW_GET + id + "/");
}

export function postStoreBranchReviewAdd(data) {
    return Axios.post(STORE_BRANCH_REVIEW_ADD, data);
}
export function patchStoreBranchReviewUpdate(id, data) {
    return Axios.patch(STORE_BRANCH_REVIEW_UPDATE + id + "/", data);
}
export function deleteStoreBranchReviewRemove(id) {
    return Axios.delete(STORE_BRANCH_REVIEW_REMOVE + id + "/");
}

export const STORE_INGREDIENT_LIST = "/Store/Ingredient/List/";
export function getIngredientsList() {
    return Axios.get(STORE_INGREDIENT_LIST)
}

export const STORE_PRODUCT_INGREDIENT_TYPES = "/Store/Product/IngredientTypes/";
export function getProductIngredientTypes() {
    return Axios.get(STORE_PRODUCT_INGREDIENT_TYPES);
}

export const STORE_INGREDIENT_ADD = "/Store/Ingredient/Add/";
export function addIngredient(formData, config) {
    return Axios.post(STORE_INGREDIENT_ADD, formData, config)
}

export const STORE_INGREDIENT_GET_ID = "/Store/Ingredient/Get/";
export function getIngredient(id) {
    return Axios.get(STORE_INGREDIENT_GET_ID + id + "/")
}

export const STORE_INGREDIENT_UPDATE_ID = "/Store/Ingredient/Update/";
export function editIngredient(id, formData, config) {
    return Axios.patch(STORE_INGREDIENT_UPDATE_ID + id + "/", formData, config)
}

export const STORE_INGREDIENT_REMOVE = "/Store/Ingredient/Remove/";
export function deleteStoreIngredient(id) {
    return Axios.delete(STORE_INGREDIENT_REMOVE + id + "/")
}


export const ORG_SERVICES_SERVICE_INSTANCES_LIST = "/Org/Services/ServiceInstances/List/"


// tax label list    /Store/Finance/Tax/Add/

export const STORE_FINANCE_TAX_LABEL_LIST = "/Store/Finance/Tax/Label/List/"
export const STORE_FINANCE_TAX_LABEL_GET = "/Store/Finance/Tax/Label/Get/"
export const STORE_FINANCE_TAX_LABEL_ADD = "/Store/Finance/Tax/Label/Add/"
export const STORE_FINANCE_TAX_LABEL_UPDATE = "/Store/Finance/Tax/Label/Update/"
export const STORE_FINANCE_TAX_LABEL_REMOVE = "/Store/Finance/Tax/Label/Remove/"

export function getStoreFinanceTaxLabelList() {
    return Axios.get(STORE_FINANCE_TAX_LABEL_LIST);
}

export function getStoreFinanceTaxLabelGet(id) {
    return Axios.get(STORE_FINANCE_TAX_LABEL_GET + id + "/");
}

export function postStoreFinanceTaxLabelAdd(data) {
    return Axios.post(STORE_FINANCE_TAX_LABEL_ADD, data);
}
export function patchStoreFinanceTaxLabelUpdate(id, data) {
    return Axios.patch(STORE_FINANCE_TAX_LABEL_UPDATE + id + "/", data);
}
export function deleteStoreFinanceTaxLabelRemove(id) {
    return Axios.delete(STORE_FINANCE_TAX_LABEL_REMOVE + id + "/");
}

// tax list 
export const STORE_FINANCE_TAX_LIST = "/Store/Finance/Tax/List/"
export const STORE_FINANCE_TAX_GET = "/Store/Finance/Tax/Get/"
export const STORE_FINANCE_TAX_ADD = "/Store/Finance/Tax/Add/"
export const STORE_FINANCE_TAX_UPDATE = "/Store/Finance/Tax/Update/"
export const STORE_FINANCE_TAX_REMOVE = "/Store/Finance/Tax/Remove/"

export function getStoreFinanceTaxList() {
    return Axios.get(STORE_FINANCE_TAX_LIST);
}

export function getStoreFinanceTaxGet(id) {
    return Axios.get(STORE_FINANCE_TAX_GET + id + "/");
}

export function postStoreFinanceTaxAdd(data, value) {
    return Axios.post(STORE_FINANCE_TAX_ADD, data, value);
}
export function patchStoreFinanceTaxUpdate(id, data) {
    return Axios.patch(STORE_FINANCE_TAX_UPDATE + id + "/", data);
}
export function deleteStoreFinanceTaxRemove(id) {
    return Axios.delete(STORE_FINANCE_TAX_REMOVE + id + "/");
}

// product addon group 
export const STORE_PRODUCT_INGREDIENT_LIST = "/Store/Product/Ingredient/List/"
export const STORE_PRODUCT_INGREDIENT_GET = "/Store/Product/Ingredient/Get/"
export const STORE_PRODUCT_INGREDIENT_ADD = "/Store/Product/Ingredient/Add/"
export const STORE_PRODUCT_INGREDIENT_UPDATE = "/Store/Product/Ingredient/Update/"
export const STORE_PRODUCT_INGREDIENT_REMOVE = "/Store/Product/Ingredient/Remove/"

export function getStoreProductIngredientList() {
    return Axios.get(STORE_PRODUCT_INGREDIENT_LIST);
}

export function getStoreProductIngredientGet(id) {
    return Axios.get(STORE_PRODUCT_INGREDIENT_GET + id + "/");
}

export function postStoreProductIngredientAdd(data) {
    return Axios.post(STORE_PRODUCT_INGREDIENT_ADD, data);
}
export function patchStoreProductIngredientUpdate(id, data) {
    return Axios.patch(STORE_PRODUCT_INGREDIENT_UPDATE + id + "/", data);
}
export function deleteStoreProductIngredientRemove(id) {
    return Axios.delete(STORE_PRODUCT_INGREDIENT_REMOVE + id + "/");
}

//Addons 

export const STORE_PRODUCT_ADDON_LIST = "/Store/Product/Addon/List/";
export const STORE_PRODUCT_ADDON_GET = "/Store/Product/Addon/Get/";
export const STORE_PRODUCT_ADDON_UPDATE = "/Store/Product/Addon/Update/"
export const STORE_PRODUCT_ADDON_NEW_ADD = "/Store/Product/Addon/Add/"



export function getStoreProductAddonList(addongroupId, productId) {
    return Axios.get(STORE_PRODUCT_ADDON_LIST + "?addon_group_id=" + addongroupId + "&&product_id=" + productId);
}

export function getStoreProductAddonGet(id) {
    return Axios.get(STORE_PRODUCT_ADDON_GET + id + "/");
}

export function postStoreProductAddonNewAdd(data, config) {
    return Axios.post(STORE_PRODUCT_ADDON_NEW_ADD, data, config);
}

export function patchStoreProductAddonUpdate(id, data, config) {
    return Axios.patch(STORE_PRODUCT_ADDON_UPDATE + id + "/", data, config);
}

// export const ORG_SERVICES_VARIANTS_LIST ="/Org/Services/Variants/List/"

export function getOrgServicesVariantsList() {
    return Axios.get(ORG_SERVICES_VARIANTS_LIST);
}


export const STORE_PRODUCT_VARIANT_PRICE_UPDATA = "/Store/Product/Variant/price/Update/"

export function patchStoreProductVatiantPriceUpdate(id, data) {
    return Axios.patch(STORE_PRODUCT_VARIANT_PRICE_UPDATA + id + "/", data);
}
//Product Name
export const PRODUCT_UNIQUE_NAME_LIST = "/Store/Product/UniqueName/List/";
export const PRODUCT_UNIQUE_NAME_ADD = "/Store/Product/UniqueName/Add/"
export const PRODUCT_UNIQUE_NAME_GET = "/Store/Product/UniqueName/Get/"
export const PRODUCT_UNIQUE_NAME_UPDATE = "/Store/Product/UniqueName/Update/"
export const PRODUCT_UNIQUE_NAME_DELETE = "/Store/Product/UniqueName/Remove/"

export function getProductUniqueNameList() {
    return Axios.get(PRODUCT_UNIQUE_NAME_LIST+"?page_size=1000")
}
export function postProductUniqueNameAdd(data) {
    return Axios.post(PRODUCT_UNIQUE_NAME_ADD, data)
}
export function getProductUniqueNameGet(id) {
    return Axios.get(PRODUCT_UNIQUE_NAME_GET + id + "/")
}
export function patchProductUniqueNameUpdate(id, data) {
    return Axios.patch(PRODUCT_UNIQUE_NAME_UPDATE + id + "/", data)
}
export function deleteProductUniqueNameRemove(id) {
    return Axios.delete(PRODUCT_UNIQUE_NAME_DELETE + id + "/")
}


//Users
export const ENTITY_PERSON_LIST = "Entity/Person/List/";
export const ORG_USER_REMOVE = "/Org/User/Remove/";

// get user
export function getUsersList() {
    return Axios.get(ENTITY_PERSON_LIST+"?page_size=1000")
   
}

// delete user
export function deleteUsersList(id) {
    return Axios.delete(ORG_USER_REMOVE + id + "/");
  }
  

//Brand
export const BRAND_LIST = "/Store/Product/Brand/List/";
export const BRAND_ADD = "/Store/Product/Brand/Add/"
export const BRAND_GET = "/Store/Product/Brand/Get/"
export const BRAND_UPDATE = "/Store/Product/Brand/Update/"
export const BRAND_DELETE = "/Store/Product/Brand/Remove/"


export function getBrandList() {
    return Axios.get(BRAND_LIST+"?page_size=1000")
}
export function postBrandAdd(data) {
    return Axios.post(BRAND_ADD, data)
}
export function getBrandGet(id) {
    return Axios.get(BRAND_GET + id + "/")
}
export function patchBrandUpdate(id, data) {
    return Axios.patch(BRAND_UPDATE + id + "/", data)
}
export function deleteBrandRemove(id) {
    return Axios.delete(BRAND_DELETE + id + "/")
}


// servie day 

export const ORG_SERVICES_BUSINESSDAYS_LIST = "/Org/Services/BusinessDays/List/"
export const ORG_SERVICES_BUSINESSDAY_GET = "/Org/Services/BusinessDay/Get/"
export const ORG_SERVICES_BUSINESSDAYS_ADD = "/Org/Services/BusinessDays/Add/"
export const ORG_SERVICES_BUSINESSDAY_UPDATE = "/Org/Services/BusinessDay/Update/"
export const ORG_SERVICES_BUSINESSDAY_DELETE = "/Org/Services/BusinessDay/Remove/"

export function getOrgServicesBusinessdysList() {
    return Axios.get(ORG_SERVICES_BUSINESSDAYS_LIST)
}
export function postOrgServicesBusinessdysAdd(data) {
    return Axios.post(ORG_SERVICES_BUSINESSDAYS_ADD, data)
}
export function getOrgServicesBusinessdysGet(id) {
    return Axios.get(ORG_SERVICES_BUSINESSDAY_GET + id + "/")
}
export function patchOrgServicesBusinessdysUpdate(id, data) {
    return Axios.patch(ORG_SERVICES_BUSINESSDAY_UPDATE + id + "/", data)
}
export function deleteOrgServicesBusinessdysRemove(id) {
    return Axios.delete(ORG_SERVICES_BUSINESSDAY_DELETE + id + "/")
}

// service hours 

export const ORG_SERVICES_BUSINESS_HOURS_LIST = "/Org/Services/BusinessHours/List/"
export const ORG_SERVICE_BUSINESS_HOURS_GET = "/Org/Services/BusinessHours/Get/"
export const ORG_SERVICE_BUSINESS_HOURS_ADD = "/Org/Services/BusinessHours/Add/"
export const ORG_SERVICES_BUSINESS_HOURS_UPDATE = "/Org/Services/BusinessHours/Update/"
export const ORG_SERVICE_BUSINESS_HOURS_ROMOVE = "/Org/Services/BusinessHours/Remove/"


export function getOrgServicesBusinessHoursList() {
    return Axios.get(ORG_SERVICES_BUSINESS_HOURS_LIST)
}
export function postOrgServicesBusinessHoursAdd(data) {
    return Axios.post(ORG_SERVICE_BUSINESS_HOURS_ADD, data)
}
export function getOrgServicesBusinessHoursGet(id) {
    return Axios.get(ORG_SERVICE_BUSINESS_HOURS_GET + id + "/")
}
export function patchOrgServicesBusinessHoursUpdate(id, data) {
    return Axios.patch(ORG_SERVICES_BUSINESS_HOURS_UPDATE + id + "/", data)
}
export function deleteOrgServicesBusinessHoursRemove(id) {
    return Axios.delete(ORG_SERVICE_BUSINESS_HOURS_ROMOVE + id + "/")
}


// service timing 


export const STORE_SERVICE_TIMING_LIST = "/Store/ServiceTiming/List/"
export const STORE_SERVICE_TIMING_GET = "/Store/ServiceTiming/Get/"
export const STORE_SERVICE_TIMING_ADD = "/Store/ServiceTiming/Add/"
export const STORE_SERVICE_TIMING_UPDATE = "/Store/ServiceTiming/Update/"



export function getStoreServiceTimingList() {
    return Axios.get(STORE_SERVICE_TIMING_LIST)
}
export function postStoreServiceTimingAddAdd(data) {
    return Axios.post(STORE_SERVICE_TIMING_ADD, data)
}
export function getStoreServiceTimigGet(id) {
    return Axios.get(STORE_SERVICE_TIMING_GET + id + "/")
}
export function patchServiceTimingUpdateUpdate(id, data) {
    return Axios.patch(STORE_SERVICE_TIMING_UPDATE + id + "/", data)
}

export const STORE_PRODUCT_BRAND_IMAGES_REMOVE = "/Store/Product/Brand/Images/Remove/"
export function patchStoreProductImagesRemove(id ,data) {
    return Axios.patch(STORE_PRODUCT_BRAND_IMAGES_REMOVE + id+"/" ,data)
}

export const STORE_PRODUCT_UNIQUENAME_IMAGES_REMOVE = "/Store/Product/UniqueName/Images/Remove/"
export function patchStoreProductUniqueNameImagesRemove(id ,data) {
    return Axios.patch(STORE_PRODUCT_UNIQUENAME_IMAGES_REMOVE + id+"/" ,data)
}

export const STORE_PRODUCT_REMOVE_CATEGORY_LOGO = "/Store/Product/RemoveCategoryLogo/"
export function deleteStoreProductRemoveCategoryLogo(id ,data) {
    return Axios.delete(STORE_PRODUCT_REMOVE_CATEGORY_LOGO + id+"/" )
}

export const STORE_PRODUCT_REMOVE_PRODUCT_LOGO = "/Store/Product/RemoveProductLogo/"
export function deleteStoreProductRemoveProductLogo(id ,data) {
    return Axios.delete(STORE_PRODUCT_REMOVE_PRODUCT_LOGO + id+"/",data )
}

export const STORE_PRODUCT_ADDON_REMOVE_LOGO = "/Store/Product/Addon/RemoveLogo/"
export function deleteStoreProductAddonRemoveLogo(id ,data) {
    return Axios.delete(STORE_PRODUCT_ADDON_REMOVE_LOGO + id+"/",data )
}


export const STORE_INGREDIENT_IMAGES_REMOVE = "Store/Ingredient/Images/Remove/";
export function deleteStoreIngredientImagesRemove(id,data) {
  return Axios.patch(STORE_INGREDIENT_IMAGES_REMOVE +id+"/",data);
}

export const STORE_TAGSET_IMAGES_REMOVE = "Store/Tagset/Images/Remove/";
export function deleteStoreTagsetImagesRemove(id,data) {
  return Axios.patch(STORE_TAGSET_IMAGES_REMOVE +id+"/",data);
}

export const STORE_TAGSET_TAGS_IMAGES_REMOVE = "Store/Tagset/Tags/Images/Remove/";
export function deleteStoreTagsetTagsImagesRemove(id,data) {
  return Axios.patch(STORE_TAGSET_TAGS_IMAGES_REMOVE +id+"/",data);
}



// New Orders

export const STORE_BASKET_ADD = "Store/Basket/Add/";
export function addBasket(data){
    return Axios.post(STORE_BASKET_ADD, data)
}

export const STORE_POS_ORDER_CREATE = "/Store/POS/Order/Create/";
export function createNewOrder(data){
    return Axios.post(STORE_POS_ORDER_CREATE, data)
}

export const STORE_ORDER_ORDERTYPE = "Store/Order/GetOrderTypes/";
export function getOrderType(){
    return Axios.get(STORE_ORDER_ORDERTYPE)
}

export const STORE_BASKET_LIST = "Store/Basket/List/";
export function getBasketList(id){
    return Axios.get(STORE_BASKET_LIST+"?order="+id)
}

export const STORE_BASKET_UPDATE = "Store/Basket/Update/"
export function updateBasketList(id, data){
    return Axios.patch(STORE_BASKET_UPDATE+id+"/", data)
}

export const STORE_BASKET_REMOVE = "/Store/Basket/Remove/"
export function deleteBasketList(id){
    return Axios.delete(STORE_BASKET_REMOVE+id+"/",)
}

export const STORE_ORDER_PAYMENT_TRANSACTION_CREATE = "Store/Order/PaymentTransaction/Create/"
export function paymentTransactionCreate(data){
    return Axios.post(STORE_ORDER_PAYMENT_TRANSACTION_CREATE, data)
}

export const STORE_LIST_PAYMENT_METHODES = "Store/List/PaymentMethods"
export function listPaymentMethods(){
    return Axios.get(STORE_LIST_PAYMENT_METHODES)
}

export const STORE_ORDER_CHECKOUT = "Store/Order/Checkout/"
export function checkoutOrder(data){
    return Axios.post(STORE_ORDER_CHECKOUT, data)
}

export const STORE_ORDER_PAYMENTTRANSACTION_LIST= "Store/Order/PaymentTransaction/List/"
export function getPaymentOrderList(id){
    return Axios.get(STORE_ORDER_PAYMENTTRANSACTION_LIST+"?order="+id)
}

export const STORE_ORDER_PAYMENTTRANSACTION_REMOVE= "Store/Order/PaymentTransaction/Remove/"
export function deletePaymentOrderList(id){
    return Axios.delete(STORE_ORDER_PAYMENTTRANSACTION_REMOVE+id+"/")
}

// export const STORE_ORDER_PAYMENTTRANSACTION_REMOVE= "Store/Order/PaymentTransaction/Remove/"
// export function deletePaymentOrderList(id){
//     return Axios.delete(STORE_ORDER_PAYMENTTRANSACTION_REMOVE+id+"/")
// }




